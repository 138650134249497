import { InjectionToken, inject } from '@angular/core';
import { RuntimeConfigurationModel } from '@core/models/runtime-configuration.model';
import { RUNTIME_CONFIGURATION } from './runtime-configuration.token';

export const STALE_AFTER = new InjectionToken<
  RuntimeConfigurationModel['staleAfter']
>('STALE_AFTER', {
  providedIn: 'root',
  factory: () => inject(RUNTIME_CONFIGURATION).staleAfter,
});
