import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AssetModel } from '@asset/models/asset.model';
import { AssetService } from '@asset/services/asset.service';
import { HttpEntityStore } from '@core/state/http-entity.store';
import { NotificationService } from '@core/services/notification.service';
import { tapResponse } from '@ngrx/component-store';
import { switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetStore extends HttpEntityStore<AssetModel> {
  constructor(
    private readonly assetService: AssetService,
    private readonly notification: NotificationService,
  ) {
    super();
  }

  readonly loadById = this.effect<AssetModel['id']>((assetId$) =>
    assetId$.pipe(
      switchMap((assetId) => {
        this.setLoading(assetId);
        return this.assetService.getAsset(assetId).pipe(
          tapResponse(
            (asset) => {
              this.setSuccess([assetId, asset]);
            },
            (error: HttpErrorResponse) => {
              this.setError([assetId, error]);
              this.notification.open({
                type: 'error',
                title: 'Failed to load asset',
                message: error.error?.detail
                  ? error.error.detail
                  : 'An unexpected error occurred while loading the asset. Please try again later.',
              });
            },
          ),
        );
      }),
    ),
  );
}
