import { HttpDataState } from '@core/types/http-data-state';
import { OperatorFunction, Subscription, first, tap } from 'rxjs';

function isStale<T>(resrouce: HttpDataState<T>, staleAfter: number): boolean {
  if (resrouce.status === 'loading') return false;
  if (!resrouce.cachedAt) return true;
  const now = new Date(Date.now());
  const staleAfterMs = staleAfter * 60 * 1000;
  const staleAt = new Date(resrouce.cachedAt.getTime() + staleAfterMs);
  if (now > staleAt) return true;
  return false;
}

export function refetchIfStale<T>(
  staleAfter: number,
  refetchEffect: () => Subscription,
): OperatorFunction<HttpDataState<T>, HttpDataState<T>> {
  return (resource$) =>
    resource$.pipe(
      first(),
      tap((resource) => {
        if (isStale(resource, staleAfter)) {
          refetchEffect();
        }
      }),
    );
}
